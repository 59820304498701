
import TransformerEdit from './TransformerEdit/TransformerEdit.js';
import HTTPCallsBuilder from './HTTPCalls/HTTPCallsBuilder.js';
import ViewContentDetails from "./HTTPCalls/ViewContentDetails.js";
import PipelineSteps from './PipelineSteps/PipelineSteps.js';
import EditPipeline from './EditPipeline/EditPipeline.js';

export default [
	PipelineSteps,
	EditPipeline,
	TransformerEdit,
	HTTPCallsBuilder,
	ViewContentDetails
];
