import template from './ViewContentDetails.html';

class ViewContentDetailsDialogViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.result_content_length = ko.observable('');
		this.result_content_headers = ko.observable('');
		this.raw_result_content_headers = ko.observable({});

		this.file_extension = ko.observable('.txt');
		this.filename = ko.observable(null);
		this.result_content = ko.observable(null);
		this.display_result = ko.observable(null);

		this.result_content_headers = ko.pureComputed(() => {
			let headers = this.raw_result_content_headers();
			return Object.keys(headers).map(key => `${key}: ${headers[key]}`).join('\n');
		});

		this.result_content.subscribe(async () => {
			if (this.result_content() === null) return null;

			// Only render text-based entries
			if (['.txt', '.html', '.json'].includes(this.file_extension()))
			{
				let txtContent = await new Response(this.result_content()).text();

				if (this.file_extension() == '.json')
				{
					txtContent = await this.parse_as_json(txtContent);
				}
				else if (this.file_extension() == '.txt')
				{
					if(this.raw_result_content_headers()['content-type'] === 'application/json')
					{
						txtContent = await this.parse_as_json(txtContent);
					}
				}
				// TODO: Prettify HTML

				this.display_result(txtContent);
			}
			else return null;
		});

		this.fetch_content();
	}

	async parse_as_json(txtContent)
	{
		try
		{
			let json_data = JSON.parse(txtContent);
			txtContent = JSON.stringify(json_data, null, 2);
		} catch (e) {
			console.error(e);
		}

		return txtContent;
	}

	btn_close_click ()
	{
		this.dialog.close(false);
	}

	async fetch_content() {
		let row = this.dialog.bindings;

		if (row.schedule_status != 'Error')
		{
			let content_response = await Grape.fetches.fetch(`/api/etl/http-call/${row.http_call_uuid}/result_content`);
			let content = await content_response.blob();

			let mime_types = {
				'text/plain': '.txt',
				'application/json': '.json',
				'application/pdf': '.pdf',
				'image/jpeg': '.jpg',
				'image/png': '.png',
				'text/html': '.html'
			};

			// Determine the file extension based on the content type
			let file_extension = mime_types[row.result_content_type] || '.txt';
			this.file_extension(file_extension);

			let fileName = `ETL_HTTP_CALL_CONTENT_${row.http_call_uuid}${file_extension}`;
			this.filename(fileName);
			this.result_content(content);

			content.lastModifiedDate = new Date();
			content.name = fileName;
		}
		else
		{
			Grape.alerts.alert({type: 'error', title: 'Error', message: `Could not get HTTP Call content! Ensure the call is correct!`});
		}
	}

	async btn_download_click ()
	{
		if (this.filename() !== null && this.result_content() !== null)
		{
			let a = document.createElement('a');
			a.href = URL.createObjectURL(this.result_content());
			a.download = this.filename();
			a.click();
		}
		else
		{
			console.log('filename', this.filename());
			console.log('result_content', this.result_content());
			Grape.alerts.alert({type: 'error', title: 'Error', message: `Could not get HTTP Call content! Ensure the call is correct!`});
		}
	}
}

class ViewContentDetailsDialogClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new ViewContentDetailsDialogViewModel(this);
		this.name = 'ViewContentDetailsDialog';
		this.element = element;

		// HACK: Remove once new ui-ps version is released
		if (!this.init_called)
			this.init();
	}

	async init ()
	{
		// HACK: Remove once new ui-ps version is released
		this.init_called = true;
		this.viewModel.result_content_length(this.bindings.result_content_length);
		this.viewModel.raw_result_content_headers(this.bindings.result_headers);
	}
}

export default {
	name: 'ViewContentDetailsDialog',
	dialog_class: ViewContentDetailsDialogClass,
	template: template,
	provider: 'ps'
};
