
import template from './PipelineSteps.html';

/**
 * View Model
 */
class PipelineStepsViewModel {
	/**
	 * ViewModel constructor.
	 * @param {PipelineStepsClass} dialog - PipelineSteps class
	 */
	constructor(dialog) {
		this.dialog = dialog;

	}

	/**
	 * Handle click event for save button
	 */
	btn_save_click() {
		this.dialog.close({});
	}

	/**
	 * Handle click event for close button
	 */
	btn_close_click() {
		this.dialog.close(false);
	}
}

/**
 * PipelineSteps
 */
class PipelineStepsClass {
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new PipelineStepsViewModel(this); // Name of the ViewModel defined above
	}

	async init()
	{
	}
}

export default {
	name: 'PipelineStepsDialog',
	dialog_class: PipelineStepsClass,
	template: template
};
