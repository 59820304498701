
import template from './EditPipeline.html';

/**
 * View Model
 */
class EditPipelineViewModel {
	/**
	 * ViewModel constructor.
	 * @param {EditPipelineClass} dialog - EditPipeline class
	 */
	constructor(dialog) {
		this.dialog = dialog;
		this.mode = ko.observable('create');
	}

	/**
	 * Handle click event for save button
	 */
	btn_save_click() {
		this.dialog.close({});
	}

	/**
	 * Handle click event for close button
	 */
	btn_close_click() {
		this.dialog.close(false);
	}
}

/**
 * EditPipeline
 */
class EditPipelineClass {
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new EditPipelineViewModel(this); // Name of the ViewModel defined above
	}

	async init()
	{
	}
}

export default {
	name: 'EditPipelineDialog',
	dialog_class: EditPipelineClass,
	template: template
};
