import template from './WorkbookList.html';

/**
 * Workbooks List page view model
 */
class WorkbooksListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);
	}

	async btnUpload_click ()
	{
		let input_element = document.body.getElementsByClassName('file-upload-input')[0];
		let form_data = new FormData();
		for (let file of input_element.files)
			form_data.append('file', file);
		let response = await fetch('/api/sheets/workbook-upload', {method: 'POST', body: form_data});
		let data = await response.text();
		this.page.reloadRecords();
	}

	async btnRefresh_click()
	{
		await this.page.reloadRecords();
	}

	row_view_entity_click (row)
	{
		Grape.navigate(`etl-ui/workbook-view?workbook_id=${row.workbook_id}`);
	}
}

/**
* Workbooks list page class
*/
class WorkbooksListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new WorkbooksListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'WorkbooksListPageClass';
		this.refresh_timeout = null;
		this.refresh_timeout_duration = 1000;
	}

	async init ()
	{
		document.title = 'All Workbooks';
	}

	async reloadRecords()
	{
		clearTimeout(this.refresh_timeout);

		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_workbooks',
			schema: 'sheets',
			sortfield: 'date_inserted',
			sortorder: 'DESC',
			limit: 100
		});

		let has_pending = false;
		result.records.forEach((rec) => {
			if (rec.sched_te === null)
				has_pending = true;
		});
		if (has_pending)
		{
			this.refresh_timeout = setTimeout(async () => {await this.reloadRecords()}, this.refresh_timeout_duration);
		}

		if (result.status == 'OK')
			this.viewModel.records(result.records || []);
		else {
			this.viewModel.records([]);
			throw new Error(result.message || result.code);
		}
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		await this.reloadRecords();

		this.viewModel.loading(false);
	}

	async teardown ()
	{
		clearTimeout(this.refresh_timeout);
	}
}

export default {
	route: '[/]etl-ui/workbook-list',
	page_class: WorkbooksListPageClass,
	template: template
};
