import template from './HTTPCallsBuilder.html';

class HTTPCallsBuilderDialogViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.mode = ko.observable('');
		this.methods = ['GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'PATCH'];
		this.selected_method = ko.observable('');
		this.url = ko.observable('');
		this.params = ko.observableArray([]);
		this.headers = ko.observableArray([]);
		this.active_tab = ko.observable('params');
		this.request_body = ko.observable('');

		this.available_auth_options = ko.observableArray(['Basic Auth', 'Bearer Token', 'API Key', 'Grape Login']);

		this.selected_auth_option = ko.observable('');

		this.available_body_options = ko.observableArray(['Text', 'File']);

		this.selected_body_option = ko.observable('');

		this.available_text_options = ko.observableArray(['JSON', 'XML', 'RAW']);

		this.selected_text_option = ko.observable('');
		this.token = ko.observable('');
		this.login_url = ko.observable('/grape/login');
		this.username = ko.observable('');
		this.password = ko.observable('');
		this.auth_header_name = ko.observable('');
		this.auth_header_value = ko.observable('');

		this.tab_style = ko.computed(() => {
			return {
				params: this.active_tab() === 'params' ? 'lightgrey' : '',
				headers: this.active_tab() === 'headers' ? 'lightgrey' : '',
				auth: this.active_tab() === 'auth' ? 'lightgrey' : '',
				body: this.active_tab() === 'body' ? 'lightgrey' : ''
			};
		});
	}

	btn_add_header_click ()
	{
		this.headers.push({ name: '', value: '' });
	}

	btn_remove_header_click (header)
	{
		this.headers.remove(header);
	}

	btn_add_param_click ()
	{
		this.params.push({ name: '', value: '' });
	}

	btn_remove_param_click (header)
	{
		this.params.remove(header);
	}

	btn_set_active_tab_click (tab)
	{
		this.active_tab(tab);
	}

	async btn_save_click ()
	{
		if (this.selected_method() == '' || this.url() == '')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Method and URL is required!'});
            return;
		}

		let obj = {
			method: this.selected_method(),
			url: this.url()
		};

		if (this.headers().length > 0)
		{
			let headers = {};
			for (let header of this.headers())
				headers[header.name] = header.value;

			obj.headers = headers;
		}

		if (this.params().length > 0)
		{
			let params = {};
			for (let param of this.params())
				params[param.name] = param.value;

			obj.query = params;
		}

		if ((this.selected_body_option() != undefined && this.selected_body_option() != '') && (this.selected_text_option() != undefined && this.selected_text_option() != ''))
			obj.content_type = `${this.selected_body_option()}/${this.selected_text_option()}`.toLowerCase();
		else if ((this.selected_body_option() != undefined && this.selected_body_option() != ''))
			obj.content_type = this.selected_body_option().toLowerCase();

		if (this.request_body() != '' && this.request_body() != undefined)
			obj.content = this.request_body();

		if (this.selected_auth_option() != undefined && this.selected_auth_option() != '')
		{
			if (this.selected_auth_option() === 'Basic Auth') {
				if ((this.username() ?? '') !== '')
					obj.authenticator = { auth_type: 'Basic Auth', options: { username: this.username(), password: this.password() } };
			}
			else if (this.selected_auth_option() === 'API Key') {
				if ((this.auth_header_name() ?? '') !== '')
					obj.authenticator = { auth_type: 'API Key', options: { auth_header_name: this.auth_header_name(), auth_header_value: this.auth_header_value() } };
			}
			else if (this.selected_auth_option() === 'Bearer Token') {
				if ((this.token() ?? '') !== '')
					obj.authenticator = { auth_type: 'Bearer Token', options: { token: this.token() } };
			}
			else if (this.selected_auth_option() === 'Grape Login') {
				if ((this.login_url() ?? '') !== '')
					obj.authenticator = { auth_type: 'Grape Login', options: { login_url: this.login_url(), username: this.username(), password: this.password() } };
			}
		}

		let result = await Grape.fetches.postJSON(`/api/etl/http-call`, obj);

		if (result.status != 'ERROR')
		{
			let submit_option = await Grape.alerts.confirm({type: 'success', title: 'HTTP call saved', message: 'Do you want to submit this HTTP call?'});

			if (submit_option)
			{
				let submit_data = await Grape.fetches.postJSON(`/api/etl/http-call/submit`, {uuid: result.uuid});

				if (submit_data.status == 'OK')
				{
					let response;
					do {
						await new Promise((resolve, reject) => setTimeout(resolve, 250));
						response = await Grape.fetches.getJSON('/api/record', {
							schema: 'ingestor',
							table: 'v_http_calls',
							fields: ['schedule_status', 'result_content_type'],
							filter: [{
								field: 'http_call_uuid',
								operator: '=',
								value: result.uuid
							}]
						});
					} while (response.status === 'OK' && response.records[0].schedule_status != 'Completed' && response.records[0].schedule_status != 'Error');

					if (response.records[0].schedule_status === 'Error')
						Grape.alerts.alert({type: 'error', title: 'Error', message: 'Could not submit this HTTP Call!'});
					else if(response.records[0].schedule_status === 'Completed')
						Grape.alerts.alert({type:'success', title: 'Success', message: 'HTTP call submitted!'});
				}
				else
					Grape.alerts.alert({type: 'error', title: 'Error', message: 'Could not submit this HTTP Call!'});
			}
		}

		this.dialog.close(true);
	}

	btn_close_click ()
	{
		this.dialog.close(false);
	}
}

class HTTPCallsBuilderDialogClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new HTTPCallsBuilderDialogViewModel(this);
		this.name = 'HTTPCallsBuilderDialog';
		this.element = element;

		if (bindings.mode)
			this.viewModel.mode(bindings.mode);
	}

	init ()
	{
		console.log('bindings: ', this.bindings);
	}
}

export default {
	name: 'HTTPCallsBuilderDialog',
	dialog_class: HTTPCallsBuilderDialogClass,
	template: template,
	provider: 'ps'
};
