import template from './HTTPCallTemplateList.html';

/**
 * HTTPCallListst page view model
 */
class HTTPCallTemplateListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);
	}

	async btn_create_entity_click () 
	{
		Grape.navigate('entities-ui/entity-create');
	}

	row_view_entity_click (row)
	{
		Grape.navigate(`entities-ui/entity-view/${row.entity_id}`, {entity_id: JSON.stringify(row.entity_id)});
	}
}

/**
* HTTPCallTemplateListst page class
*/
class HTTPCallTemplateListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new HTTPCallTemplateListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'HTTPCallTemplateListPageClass';
	}

	async init ()
	{
		document.title = 'HTTP calls';
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_http_calls',
			schema: 'ingestor',
			sortfield: 'date_inserted',
			sortorder: 'DESC',
			limit: 100
		});

		if (result.status == 'OK')
			this.viewModel.records(result.records || []);
		else {
			this.viewModel.records([]);
			throw new Error(result.message || result.code);
		}

		this.viewModel.loading(false);
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]etl-ui/http-call-templates',
	page_class: HTTPCallTemplateListPageClass,
	template: template
};

