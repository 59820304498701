import template from './ko-select-with-input.html';

class SelectWithInputComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_items = ko_helper.safe_observableArray(params.available_items);
		this.selected_item = ko_helper.safe_observable(params.selected_item || '');
		this.title = ko.observable(params.title || 'Choose or Input');
		this.new_item = ko.observable('');

		this.new_item.subscribe((value) => {
			if (value.length > 0)
				this.selected_item(value);
		});
	}

	item_click (row)
	{
		this.new_item('');
		this.selected_item(row);
	}

	btn_add_item_click ()
	{
		if (document.querySelector('.ps-dropdown-menu').style.display = 'block')
			document.querySelector('.ps-dropdown-menu').style.display = '';
	}
}

export default {
	name: 'ko-select-with-input',
	viewModel: SelectWithInputComponentViewModel,
	module_type: 'ko',
	template: template
}
