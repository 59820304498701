import template from './HTTPCallList.html';

/**
 * HTTPCallList page view model
 */
class HTTPCallListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.el = page.element;
		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);

		this.format_date = (time_sched) => {
			if (!time_sched) return 'N/A';
			return moment(time_sched).format('YYYY-MM-DD HH:mm');
		};
	}

	async btn_submit_call_click (row)
	{
		let submit_data = await Grape.fetches.postJSON(`/api/etl/http-call/submit`, {uuid: row.http_call_uuid});

		if (submit_data.status == 'OK')
		{
			let response;
			do {
				await new Promise((resolve, reject) => setTimeout(resolve, 250));
				response = await Grape.fetches.getJSON('/api/record', {
					schema: 'ingestor',
					table: 'v_http_calls',
					fields: ['schedule_status', 'result_content_type'],
					filter: [{
						field: 'http_call_uuid',
						operator: '=',
						value: row.http_call_uuid
					}]
				});
			} while (response.status === 'OK' && response.records[0].schedule_status != 'Completed' && response.records[0].schedule_status != 'Error');

			if (response.records[0].schedule_status === 'Completed')
				Grape.alerts.alert({type:'success', title: 'Success', message: 'HTTP call submitted!'});
		}
		else
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Could not submit this HTTP Call!'});

		this.page.updateData();
	}

	async btn_retry_call_click (row)
	{
		let retry_data = await Grape.fetches.postJSON(`/api/etl/http-call/retry`, {uuid: row.http_call_uuid});

		if (retry_data.status == 'OK')
		{
			let response;
			do {
				await new Promise((resolve, reject) => setTimeout(resolve, 250));
				response = await Grape.fetches.getJSON('/api/record', {
					schema: 'ingestor',
					table: 'v_http_calls',
					fields: ['schedule_status', 'result_content_type'],
					filter: [{
						field: 'http_call_uuid',
						operator: '=',
						value: row.http_call_uuid
					}]
				});
			} while (response.status === 'OK' && response.records[0].schedule_status != 'Completed' && response.records[0].schedule_status != 'Error');

			if (response.records[0].schedule_status === 'Completed')
				Grape.alerts.alert({type:'success', title: 'Success', message: 'HTTP call retried!'});
		}
		else
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Could not retry this HTTP Call!'});

		this.page.updateData();
	}

	btn_view_logfile_click (row)
	{
		window.open('/api/bgworker/schedules/'+row.schedule_id+'/log', '_blank');
	}

	async btn_view_content_click (row)
	{
		Grape.dialog.show('ViewContentDetailsDialog', row);
	}

	async btn_download_click (row)
	{
		if (row.schedule_status != 'Error')
		{
			let content_response = await Grape.fetches.fetch(`/api/etl/http-call/${row.http_call_uuid}/result_content`);
			let content = await content_response.blob();

			let mime_types = {
				'text/plain': '.txt',
				'application/json': '.json',
				'application/pdf': '.pdf',
				'image/jpeg': '.jpg',
				'image/png': '.png',
				'text/html': '.html'
			};

			// Determine the file extension based on the content type
			let file_extension = mime_types[row.result_content_type] || '.txt';

			let fileName = `ETL_HTTP_CALL_CONTENT_${row.http_call_uuid}${file_extension}`;

			content.lastModifiedDate = new Date();
			content.name = fileName;

			let a = document.createElement('a');
			a.href = URL.createObjectURL(content);
			a.download = fileName;
			a.click();
		}
		else
		{
			Grape.alerts.alert({type: 'error', title: 'Error', message: `Could not get HTTP Call content! Ensure the call is correct!`});
		}
	}

	async btn_create_http_call_click ()
	{
		let response = await Grape.dialog.open('HTTPCallsBuilderDialog', {mode: 'create'});

		if (response)
			this.page.updateData();
	}
}

/**
* HTTPCallList page class
*/
class HTTPCallListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new HTTPCallListPageViewModel(this);
		this.viewModel.element = element;
		this.name = 'HTTPCallListPageClass';
	}

	async init ()
	{
		document.title = 'HTTP calls';
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_http_calls',
			schema: 'ingestor',
			sortfield: 'time_sched',
			sortorder: 'DESC',
			limit: 100
		});

		if (result.status == 'OK')
			this.viewModel.records(result.records || []);
		else
		{
			this.viewModel.records([]);
			throw new Error(result.message || result.code);
		}

		this.viewModel.loading(false);
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]etl-ui/http-calls',
	page_class: HTTPCallListPageClass,
	template: template
};
