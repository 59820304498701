/**
 * The Pipelines page displays a list of all ETL pipelines, and eventually Batch Types associated with them. It should include functions for editing and viewing everything related.
 */

import template from './Pipelines.html';

/**
 * Pipelines Page view model
 */
class PipelinesPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.loading = ko.observable(false);
		this.records = ko.observableArray([]);
	}

	async update() {
	}

	async btn_create_pipeline_click ()
	{
		let response = await Grape.dialog.open('EditPipelineDialog', {mode: 'create'});

		if (response)
			this.page.updateData();

		// POST: /api/etl/pipeline
	}

	async btn_edit_steps_click (row)
	{
		let response = await Grape.dialog.show('PipelineStepsDialog', row);
		if (response)
			this.page.updateData();

		// DELETE: /api/etl/pipeline
	}
}

/**
 * Pipelines page class
 */
class PipelinesPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new PipelinesPageViewModel(this);
		this.name = 'PipelinesPageClass';
	}

	async init() {
	}

	async updateData ()
	{
		this.viewModel.loading(true);

		// Load all entities
		let result = await Grape.fetches.getJSON('/api/record', {
			table: 'v_pipelines',
			schema: 'etl',
			sortfield: 'pipeline_id',
			sortorder: 'DESC',
			limit: 100
		});

		if (result.status == 'OK')
			this.viewModel.records(result.records || []);
		else {
			this.viewModel.records([]);
			throw new Error(result.message || result.code);
		}

		this.viewModel.loading(false);
	}

	async teardown() {
	}
}

export default {
	route: '[/]etl-ui/pipelines',
	page_class: PipelinesPageClass,
	template: template
}
